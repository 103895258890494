<template>
  <div class="footer">
    <b-container class="wrap">
      <div class="left">
        <div>
          <img src="../assets/logo.png" alt="" />
          <div class="copyright">
            Copyright © 萌酷信息科技有限责任公司 2021 保留一切权力
            <a href="http://beian.miit.gov.cn/" style="color: #ffffff;" target="_blank">粤ICP备2021053698号</a>
          </div>
        </div>
      </div>
      <div class="right">
        <div class="rblock">
          <router-link tag="h5" class="tit" to="/aboutUs">简介</router-link>
        </div>
        <div class="rblock">
          <router-link class="tit" tag="h5" to="ourBusiness">案例</router-link>
          <div><a href="">虚拟仿真方向</a></div>
          <div><a href="">数字化方向</a></div>
          <div><a href="">其他项目</a></div>
        </div>
        <div class="rblock">
          <router-link class="tit" tag="h5" to="/new">新闻</router-link>
          <div><a href="">新闻动态</a></div>
        </div>
        <div class="rblock">
          <router-link class="tit" tag="h5" to="/addUs">加入我们</router-link>
          <div><a href="">招聘岗位</a></div>
        </div>
      </div>
    </b-container>
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
};
</script>

<style lang="scss" scoped>
.container {
  padding: 0;
}
.footer {
  padding: 72px 0;
  width: 100%;
  box-sizing: border-box;
  overflow: hidden;
  background: #3c8cff;
  margin-left: 0px !important;
  margin-right: 0px !important;
  color: #ffffff;
  margin: 0 auto;
  .wrap {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .left {
      cursor: pointer;
      .copyright {
        font-size: 16px;
        font-weight: 100;
        margin-top: 50px;
      }
      img {
        /* 左侧logo */
        width: 218px;
        margin-bottom: 1rem;
      }
    }
    .right {
      margin-top: -55px;
      width: 40%;
      display: flex;
      .rblock {
        cursor: pointer;
        width: 25%;
        margin: 0 0 0 30px;
        .tit {
          margin-bottom: 20px;
        }
        a {
          text-decoration: none;
          color: white;
          font-size: 14px;
        }
      }
    }
  }
}
.filing {
  background-color: #3c8cff;
  padding-bottom: 20px;
  text-align: center;
  a {
    color: #fff;
  }
  a:hover {
    color: #fff;
    text-decoration: none;
  }
}
@media (max-width: 1000px) {
  .footer {
    // display: none !important;
    padding: 0 20px;
    .wrap {
      padding: 26px 0 24px;
      .left {
        width: 21.33%;
        div {
          width: 100%;
          img {
            width: 100%;
            margin-bottom: 0rem;
          }
          .copyright {
            display: none;
          }
        }
      }
      .right {
        margin-top: 0px;
        width: 70%;
        .rblock {
          margin: 0 0 0 0px;
          width: 25%;
          .tit {
            margin-bottom: 0px;
          }
          h5 {
            font-size: 12px;
            margin-bottom: 0rem;
          }
          div {
            display: none;
          }
        }
      }
    }
  }
}
</style>