export const index = [ //首页的router
    {
        path: '/',
        redirect: '/index',

    },
    {
        path: '/index',
        component: () =>
            import ('@/views/index/index'),
    },
    {
        path: '/aboutUs',
        component: () =>
            import ('@/views/aboutUs/aboutUs'),
    },
    {
        path: '/addUs',
        component: () =>
            import ('@/views/addUs/addUs'),
    },
    {
        path: '/ourBusiness',
        component: () =>
            import ('@/views/ourBusiness/ourBusiness'),

    },
    {
        path: '/new',
        component: () =>
            import ('@/views/new/new'),
    }, {
        path: '/ourBusiness/detail',
        component: () =>
            import ('@/views/ourBusiness/detail'),
    },
    {
        path: '/new/detail',
        component: () =>
            import ('@/views/new/detail'),
    },
]