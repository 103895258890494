<template>
  <div>
    <b-navbar
      ref="navbar"
      type="light"
      fixed="top"
      toggleable="lg"
      class="clearfix"
    >
      <b-container>
        <b-navbar-brand>
          <router-link to="/">
            <img src="../assets/logo.png" class="logo" />
            <img src="../assets/mlogo.png" alt="" class="mlogo" />
          </router-link>
        </b-navbar-brand>
        <button
          type="button"
          aria-label="Toggle navigation"
          @click="toggleClick"
          class="navbar-toggler collapsed"
          aria-expanded="false"
          ref="btn"
        >
          <img v-if="isShow" src="../assets/navbtn.png" alt="" />
          <img v-else src="../assets/navclose.png" alt="" />
        </button>

        <b-collapse ref="collapse" id="nav-collapse" is-nav>
          <ul class="navbar-nav">
            <li class="nav-item index" @click="toggleClick">
              <router-link class="nav-link" to="/">首页</router-link>
            </li>
            <li
              class="nav-item"
              v-for="(item, index) in nav"
              :key="index"
              @click="toggleClick"
            >
              <router-link class="nav-link" :to="item.navLink"
                >{{ item.navName }}
              </router-link>
            </li>
          </ul>
        </b-collapse>
      </b-container>
    </b-navbar>
  </div>
</template>

<script>
export default {
  data() {
    return {
      isShow: true,
      flag: true,
      nav: [
        { navName: "简介", navLink: "/aboutUs" },
        { navName: "案例", navLink: "/ourBusiness" },
        { navName: "新闻", navLink: "/new" },
        { navName: "加入我们", navLink: "/addUs" },
      ],
      miniflag: true,
    };
  },

  mounted() {
    window.addEventListener("scroll", this.handleScroll, true); //
  },
  methods: {
    toggleClick() {
      if (document.body.scrollWidth < 768) {
        //H5菜单展示
        if ((this.miniflag = !this.miniflag)) {
          this.$refs.collapse.$el.style.transform = "translateX(0%)";
          this.isShow = false;
        } else {
          this.$refs.collapse.$el.style.transform = "translateX(110%)";
          this.isShow = true;
        }
      }
    },
    handleScroll() {
      // 页面滚动距顶部距离
      var scrollTop =
        window.pageYOffset ||
        document.documentElement.scrollTop ||
        document.body.scrollTop;
      var scroll = scrollTop - this.i;
      this.i = scrollTop;
      if (scroll < 0) {
        // console.log("up");
        this.$refs.navbar.$el.style.transform = "translateY(0%)";
      } else {
        if (document.body.clientWidth < 768) {
          //移动端滑动时隐藏nav
          // this.$refs.navbar.$el.style.transform = "translateY(-5rem)";
          // this.$refs.collapse.$el.style.transform = "translateX(110%)";
        }
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.navbar {
  background-color: #3c8cff;
  height: 80px;
  transition: all 0.5s ease;
  .container {
    padding: 0;
    justify-content: space-between;
    align-items: center;
    .navbar-brand {
      .mlogo {
        display: none !important;
        margin: auto;
        width: 80px;
      }
      .logo {
        display: inline-block !important;
        width: 160px;
        margin: 8px 8px 8px 0;
      }
    }
    .index {
      display: none;
    }

    .nav-item {
      // margin-top: 20px;
      margin-left: 0rem;
      .nav-link {
        letter-spacing: 0.5px;
        display: flex;
        height: 3rem;
        align-items: center;
        width: 7rem;
        justify-content: center;
        font-size: 18px;
        font-family: Source Han Sans CN;
        font-weight: 500;
        line-height: 31px;
        color: #fff;
      }
      .router-link-active::after {
        position: absolute;
        bottom: 0px;
        content: "";
        width: 78px;
        height: 4px;
        background: #ffffff;
        border-radius: 2px;
      }
    }
    .nav-collapse {
      flex: 1;
      flex-grow: 0 !important;
      .navbar-nav {
        .nav-link {
          color: #fff;
        }
      }
    }
  }
}

.navbar-collapse {
  flex-grow: 0 !important;
}
.navbar-light .navbar-nav .nav-link {
  color: #fff;
}
@media (max-width: 968px) {
  .navbar-light {
    border-color: transparent;
  }
  .navbar .navbar-toggler {
  }
  .navbar-light .navbar-toggler {
    border-color: transparent;
  }
  .navbar {
    background-color: #fff;
    transform: translateY(0rem);
    height: 60px;
    .container {
      .navbar-brand {
        margin-left: 4rem;
        .logo {
          display: none !important;
        }
        .logoName {
          display: none !important;
        }
        .mlogo {
          display: block !important;
          position: absolute;
          left: 50%;
          top: 50%;
          transform: translate(-50%, -50%);
          width: 80px;
        }
      }
    }
    .navbar-toggler {
      color: rgba(0, 0, 0, 0.5);
    }
    .index {
      display: block;
    }
    .navbar-collapse {
      display: block !important;
      position: absolute;
      background: white;
      right: 0rem;
      top: 50px;
      height: 100vh;
      transition: all 0.8s ease;
      transform: translateX(110%);
      flex-grow: 0 !important;
    }
  }
  .navbar .container .nav-item {
    padding: 15px 0;
  }
  .navbar .container .nav-item .nav-link {
    color: #333333;
    width: 12.5rem;
    font-size: 16px;
  }
  .navbar .container .index {
    display: block;
  }
}
.navbar-toggler {
  img {
    width: 20px;
    height: 20px;
  }
}
</style>