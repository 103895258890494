<template>
  <div id="app">
    <navBar></navBar>
    <router-view></router-view>
    <img
      src="@/assets/top.png"
      alt=""
      ref="topbtn"
      class="topbtn"
      @click="topFunction"
    />
    <cuFooter></cuFooter>
  </div>
</template>

<script>
import navBar from "@/components/navBar.vue";
import cuFooter from "@/components/footer.vue";

export default {
  components: {
    navBar,
    cuFooter,
  },
  methods: {
    topFunction() {
      window.scrollTo(0, 0);
    },
    handleScroll() {
      //获取滚动距顶部的距离，显示
      let scrollTop =
        window.pageYOffset ||
        document.documentElement.scrollTop ||
        document.body.scrollTop;
      if (scrollTop > 400) {
        this.$refs.topbtn.style.display = "block";
      } else {
        this.$refs.topbtn.style.display = "none";
      }
    },
  },
  mounted() {
    window.addEventListener("scroll", this.handleScroll, true); // 监听（绑定）滚轮滚动事件
  },
  destroyed() {
    window.removeEventListener("scroll", this.handleScroll); //离开页面需要移除这个监听的事件
  },
};
</script>

<style lang="scss">
// @import "@/scss/custom.scss";
#app {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  // color: #2c3e50;
  // font-family: Source Han Sans CN;
  font-family: "Source Han Sans CN";
}
* {
  margin: 0;
  padding: 0;
  outline: 0 none;
  word-wrap: break-word;
  word-break: break-word;
}
a {
  text-decoration: none !important;
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
  margin: 0;
  padding: 0;
}
ul,
ol,
li {
  list-style: none;
}
//
.topbtn {
  display: none;
  position: fixed;
  z-index: 1111;
  bottom: 5rem;
  right: 10px;
  width: 60px;
  height: 60px;
}
@media (min-width: 1200px) {
  .container,
  .container-sm,
  .container-md,
  .container-lg,
  .container-xl {
    max-width: 1200px !important;
  }
}
@media (max-width: 768px) {
  #app {
    font-family: "pingFang";
  }
  .topbtn {
    position: fixed;
    z-index: 1111;
    bottom: 5rem;
    right: 10px;
    width: 28px;
    height: 28px;
  }
}
</style>
