import Vue from 'vue'
import App from './App.vue'
import router from './router'

import $ from 'jquery';
window.jQuery = $;
window.$ = $;

import {
    BootstrapVue,
    IconsPlugin
} from 'bootstrap-vue'
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'
import { BIcon } from 'bootstrap-vue'
import { BootstrapVueIcons } from 'bootstrap-vue'

Vue.use(BootstrapVue)
Vue.use(IconsPlugin)
Vue.use(BootstrapVueIcons)
Vue.component('b-icon', BIcon)

// import './assets/font/font.css'
import VueAwesomeSwiper from 'vue-awesome-swiper'
Vue.use(VueAwesomeSwiper)

Vue.config.productionTip = false
new Vue({
    router,
    render: h => h(App),
}).$mount('#app')